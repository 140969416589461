import React, { useEffect, useContext } from 'react';
import ChessPiece from './ChessPiece.js';
import { useGLTF } from '@react-three/drei';
import { ThemeContext } from './ThemeContext.js';
// import { addGUIFolders } from '../GUIManager.js';

const pieceScales = {
	"king": [0.4, 0.4, 0.4],
	"queen": [0.35, 0.4, 0.35],
	"bishop": [0.3, 0.3, 0.3],
	"knight": [0.3, 0.32, 0.3],
	"rook": [0.25, 0.25, 0.25],
	"pawn": [0.15, 0.15, 0.15],
};

const ChessPieces = () => {

	const { nodes: pieceNodes } = useGLTF('/pieces.glb');
	const { theme, setTheme } = useContext(ThemeContext);

	const squares = [];


	const {
		player1PiecesColor, player1PiecesMetalness, player1PiecesRoughness,
		player2PiecesColor, player2PiecesMetalness, player2PiecesRoughness
	} = theme.pieces

	for (let i = 0; i < 8; i++) {
		squares.push(
			<ChessPiece
				key={`pawn-1${i}`}
				position={[i - 3.5, 0.1, -2.5]}
				color={player1PiecesColor}
				metalness={player1PiecesMetalness}
				roughness={player1PiecesRoughness}
				geometry={pieceNodes.pawn.geometry}
				scale={pieceScales.pawn}
			/>
		);
	}

	for (let i = 0; i < 8; i++) {

		squares.push(
			<ChessPiece
				key={`pawn-2${i}`}
				position={[i - 3.5, 0.1, 2.5]}
				color={player2PiecesColor}
				metalness={player2PiecesMetalness}
				roughness={player2PiecesRoughness}
				geometry={pieceNodes.pawn.geometry}
				scale={pieceScales.pawn}
			/>
		);
	}


	squares.push(
		<ChessPiece
			key="rook11"
			position={[0 - 3.5, 0.1, -3.5]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.rook.geometry}
			scale={pieceScales.rook}
		/>
	);

	squares.push(
		<ChessPiece
			key="knight11"
			position={[-2.5, 0.1, -3.5]}
			rotation={[0, Math.PI / 2, 0]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.knight.geometry}
			scale={pieceScales.knight}
		/>
	);

	squares.push(
		<ChessPiece
			key="bishop11"
			position={[-1.5, 0.1, -3.5]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.bishop.geometry}
			scale={pieceScales.bishop}
		/>
	);

	squares.push(
		<ChessPiece
			key="king1"
			position={[-0.5, 0.1, -3.5]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.king.geometry}
			scale={pieceScales.king}
		/>
	);

	squares.push(
		<ChessPiece
			key="queen1"
			position={[0.5, 0.1, -3.5]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.queen.geometry}
			scale={pieceScales.queen}
		/>
	);

	squares.push(
		<ChessPiece
			key="bishop12"
			position={[1.5, 0.1, -3.5]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.bishop.geometry}
			scale={pieceScales.bishop}
		/>
	);

	squares.push(
		<ChessPiece
			key="knight12"
			position={[2.5, 0.1, -3.5]}
			rotation={[0, Math.PI / 2, 0]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.knight.geometry}
			scale={pieceScales.knight}
		/>
	);

	squares.push(
		<ChessPiece
			key="rook12"
			position={[3.5, 0.1, -3.5]}
			color={player1PiecesColor}
			metalness={player1PiecesMetalness}
			roughness={player1PiecesRoughness}
			geometry={pieceNodes.rook.geometry}
			scale={pieceScales.rook}
		/>
	);

	squares.push(
		<ChessPiece
			key="rook21"
			position={[-3.5, 0.1, 3.5]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.rook.geometry}
			scale={pieceScales.rook}
		/>
	);

	squares.push(
		<ChessPiece
			key="knight21"
			position={[-2.5, 0.1, 3.5]}
			rotation={[0, Math.PI / -2, 0]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.knight.geometry}
			scale={pieceScales.knight}
		/>
	);

	squares.push(
		<ChessPiece
			key="bishop21"
			position={[-1.5, 0.1, 3.5]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.bishop.geometry}
			scale={pieceScales.bishop}
		/>
	);

	squares.push(
		<ChessPiece
			key="queen2"
			position={[-0.5, 0.1, 3.5]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.queen.geometry}
			scale={pieceScales.queen}
		/>
	);

	squares.push(
		<ChessPiece
			key="king2"
			position={[0.5, 0.1, 3.5]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.king.geometry}
			scale={pieceScales.king}
			receiveShadow castShadow
		/>
	);

	squares.push(
		<ChessPiece
			key="bishop22"
			position={[1.5, 0.1, 3.5]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.bishop.geometry}
			scale={pieceScales.bishop}
		/>
	);

	squares.push(
		<ChessPiece
			key="knight22"
			position={[2.5, 0.1, 3.5]}
			rotation={[0, Math.PI / -2, 0]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.knight.geometry}
			scale={pieceScales.knight}
		/>
	);

	squares.push(
		<ChessPiece
			key="rook22"
			position={[3.5, 0.1, 3.5]}
			color={player2PiecesColor}
			metalness={player2PiecesMetalness}
			roughness={player2PiecesRoughness}
			geometry={pieceNodes.rook.geometry}
			scale={pieceScales.rook}
		/>
	);

	return squares;
};

export default ChessPieces;

import React, { useContext } from 'react';
import { Text, meshStandardMaterial } from '@react-three/drei';
import ChessPieces from './ChessPieces';
import { ThemeContext } from './ThemeContext.js';
// import { addGUIFolders } from '../GUIManager.js';
import Square from './Square';
import { Box } from '@react-three/drei';

const Stage = () => {
	const { theme, setTheme } = useContext(ThemeContext);

	const squares = [];


	const boardFolders = [
		{
			parent: "Show Details",
			title: "Board",
			controllers: [
				{
					label: 'Color',
					type: 'color',
					property: 'color',
					object: theme.board,
					onChange: (color) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							board: {
								...prevTheme.board,
								color: color,
							},
						}));
					},
				},
				{
					label: 'Metalness',
					type: 'number',
					property: 'metalness',
					object: theme.board,
					min: 0,
					max: 1,
					step: 0.01,
					onChange: (value) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							board: {
								...prevTheme.board,
								metalness: value,
							},
						}));
					},
				},
				{
					label: 'Roughness',
					type: 'number',
					property: 'roughness',
					object: theme.board,
					min: 0,
					max: 1,
					step: 0.01,
					onChange: (value) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							board: {
								...prevTheme.board,
								roughness: value,
							},
						}));
					},
				},

				{
					parent: 'Board',
					label: 'Black Squares Color',
					type: 'color',
					property: 'blackSquareColor',
					object: theme.squares,
					onChange: (color) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							squares: {
								...prevTheme.squares,
								blackSquareColor: color,
							},
						}));
					},
				},
				{
					label: 'Black Squares Metalness',
					type: 'number',
					property: 'blackSquareMetalness',
					object: theme.squares,
					min: 0,
					max: 1,
					step: 0.01,
					onChange: (value) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							squares: {
								...prevTheme.squares,
								blackSquareMetalness: value,
							},
						}));
					},
				},
				{
					label: 'Black Squares Roughness',
					type: 'number',
					property: 'blackSquareRoughness',
					object: theme.squares,
					min: 0,
					max: 1,
					step: 0.01,
					onChange: (value) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							squares: {
								...prevTheme.squares,
								blackSquareRoughness: value,
							},
						}));
					},
				},
				{
					label: 'White Squares Color',
					type: 'color',
					property: 'whiteSquareColor',
					object: theme.squares,
					onChange: (color) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							squares: {
								...prevTheme.squares,
								whiteSquareColor: color,
							},
						}));
					},
				},
				{
					label: 'White Squares Metalness',
					type: 'number',
					property: 'whiteSquareMetalness',
					object: theme.squares,
					min: 0,
					max: 1,
					step: 0.01,
					onChange: (value) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							squares: {
								...prevTheme.squares,
								whiteSquareMetalness: value,
							},
						}));
					},
				},
				{
					label: 'White Squares Roughness',
					type: 'number',
					property: 'whiteSquareRoughness',
					object: theme.squares,
					min: 0,
					max: 1,
					step: 0.01,
					onChange: (value) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							squares: {
								...prevTheme.squares,
								whiteSquareRoughness: value,
							},
						}));
					},
				},
			]
		}
	];

	// addGUIFolders(folders);

	const squareSize = 1;
	const chessboardSize = 10 * squareSize;
	const borderThickness = 0.2;
	const color = theme.board.color || '#00ffff';
	const squareColors = [
		theme.squares.whiteSquareColor,
		theme.squares.blackSquareColor];

	const squareMetalness = [
		theme.squares.whiteSquareMetalness,
		theme.squares.blackSquareMetalness];

	const squareRoughness = [
		theme.squares.whiteSquareRoughness,
		theme.squares.blackSquareRoughness];

	// Inside Stage component
	for (let i = 0; i < 8; i++) {
		for (let j = 0; j < 8; j++) {
			const color = squareColors[(i + j) % 2];
			const metalness = squareMetalness[(i + j) % 2];
			const roughness = squareRoughness[(i + j) % 2];
			squares.push(
				<Square
					key={`square-${i}-${j}`}
					position={[i - 3.5, 0, j - 3.5]}
					color={color}
					metalness={metalness}
					roughness={roughness}
					receiveShadow
				/>
			);


		}
	}

	// Inside Stage component
	squares.push(
		<Box key="topBorder" args={[chessboardSize, borderThickness, chessboardSize]} position={[0, -.059, 0]}>
			<meshStandardMaterial
				attach="material"
				color={color}
				metalness={theme.board.metalness}
				roughness={theme.board.roughness}
			/>
		</Box>
	);

	return (
		<>
			{squares}
			<ChessPieces />
		</>
	);
};

export default Stage;

import React, { useState, useEffect, useRef, useContext } from 'react';
import { GUI } from 'dat.gui';
import themePresets from './guiManagers/themePresets.json';
import { ThemeContext } from './chessGame/ThemeContext.js';
import { addGUIFolders } from './GUIs/GUICreator.js';
import { useChessGameGUIFolders } from './GUIs/guiFolders.js';

const GUIManager = () => {
    const guiContainerRef = useRef(null);
    const { theme, setTheme } = useContext(ThemeContext);
    const chessGameGUIFolders = useChessGameGUIFolders();
    const [guiInstance, setGuiInstance] = useState(null);
    const [guiFoldersReady, setGuiFoldersReady] = useState(false);
    const [presetIndex, setPresetIndex] = useState(0);

    useEffect(() => {
        const newGuiInstance = new GUI({ autoPlace: false });
        guiContainerRef.current.appendChild(newGuiInstance.domElement);
        setGuiInstance(newGuiInstance);
    }, []);

    useEffect(() => {
        if (guiInstance && chessGameGUIFolders && !guiFoldersReady) {
            addGUIFolders(guiInstance, chessGameGUIFolders);
            setGuiFoldersReady(true);
        }
    }, [guiInstance, chessGameGUIFolders, guiFoldersReady]);

    useEffect(() => {
        updateGUIControls();
    }, [theme, guiFoldersReady]);

    const updateGUIControls = () => {
        if (!guiInstance || !chessGameGUIFolders) {
            return;
        }

        const updateControllers = (folderData, parentFolder = null) => {
            for (const [key, value] of Object.entries(folderData)) {
                if (key === 'controllers') {
                    for (const controllerData of value) {
                        const controller = parentFolder.__controllers.find(
                            (c) => c.property === controllerData.property
                        );
                        if (controller) {
                            const newValue = controllerData.object[controllerData.property];
                            if (newValue !== undefined && newValue !== controller.getValue()) {
                                controller.setValue(newValue);
                            }
                        }
                    }
                } else {
                    const folder = parentFolder && parentFolder.__folders[key];
                    if (folder) {
                        updateControllers(value, folder);
                    }
                }
            }
        };

        updateControllers(chessGameGUIFolders);
    };


    const randomizeColors = () => {
        // console.log("randomizeColors start theme", theme.scene)

        const randomColor = () => Math.floor(Math.random() * 256);
        const randomMetalness = () => Math.random();
        const randomRoughness = () => Math.random();

        // Create a new theme object with randomized values
        const newTheme = {

            scene: {
                backgroundColor: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
            },
            lights: {
                ambientLight: {
                    intensity: randomRoughness(),
                },
                directionalLight1: {
                    color: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                    intensity: randomRoughness(),
                    position: [7, 5, -10], // Specific position
                },
                directionalLight2: {
                    color: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                    intensity: randomRoughness(),
                    position: [-7, 5, 10], // Specific position
                },
                pointLight1: {
                    color: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                    intensity: randomRoughness(),
                    position: [10, 1, 0], // Specific position
                },
                pointLight2: {
                    color: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                    intensity: randomRoughness(),
                    position: [-10, 1, 0], // Specific position
                },
            },
            board: {
                color: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                metalness: randomMetalness(),
                roughness: randomRoughness(),
            },
            squares: {
                blackSquareColor: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                blackSquareMetalness: randomMetalness(),
                blackSquareRoughness: randomRoughness(),
                whiteSquareColor: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                whiteSquareMetalness: randomMetalness(),
                whiteSquareRoughness: randomRoughness(),
            },
            pieces: {
                player1PiecesColor: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                player1PiecesMetalness: randomMetalness(),
                player1PiecesRoughness: randomRoughness(),
                player2PiecesColor: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
                player2PiecesMetalness: randomMetalness(),
                player2PiecesRoughness: randomRoughness(),
            },
        };

        // Update the context with the new theme
        setTheme(newTheme);
        // console.log("set newTheme", newTheme.scene)
        // console.log('Context updated with new theme:', newTheme);

        // console.log('After update GUI controls:', guiInstance.__folders);
        // console.log("randomizeColors after theme", theme.scene)

    };


    const updatePreset = (index) => {
        const preset = themePresets[index];
        setTheme(preset); // Update the context with the preset

        // Iterate through each folder in chessGameGUIFolders
        for (const folderGroup of Object.values(chessGameGUIFolders)) {
            for (const folderData of folderGroup) {
                // Navigate to the folder within the GUI instance
                const parentFolder = guiInstance.__folders[folderData.parent];
                const folder = parentFolder && parentFolder.__folders[folderData.title];

                // Iterate through each controller in the folder
                for (const controllerData of folderData.controllers) {
                    const controller = folder && folder.__controllers.find(controller => controller.property === controllerData.property);

                    if (controller) {
                        // Check if the new value from the preset is different from the current one
                        const newValue = preset[controllerData.object] && preset[controllerData.object][controllerData.property];
                        const currentValue = controller.getValue();
                        if (newValue !== undefined && newValue !== currentValue) {
                            // Update the controller's value with the new value from the preset
                            controller.setValue(newValue);
                            console.log(`-Pass: Updated ${controllerData.property} with new value:`, newValue);
                        } else {
                            console.log(`-No Change: ${controllerData.property} value is already up to date.`);
                        }
                    } else {
                        console.log(`-Fail: ${controllerData.property} controller not found.`);
                    }
                }
            }
        }
    };

    const handlePrevClick = () => {
        setPresetIndex((prevIndex) => (prevIndex - 1 + themePresets.length) % themePresets.length);
        updatePreset(presetIndex);
    };

    const handleNextClick = () => {
        setPresetIndex((prevIndex) => (prevIndex + 1) % themePresets.length);
        updatePreset(presetIndex);
    };

    return (
        <div id="gui-container" ref={guiContainerRef}>
            <div id="gui-top-buttons">
                <span style={{ color: "white" }}>{themePresets[presetIndex]?.name || 'No Name'}</span>
                <br></br>
                <button onClick={handlePrevClick}>Previous</button>
                <button onClick={handleNextClick}>Next</button>
                <br></br>
                <button onClick={randomizeColors}>Randomize</button>
            </div>
        </div>
    );
};

export default GUIManager;

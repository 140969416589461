import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext.js'; // Adjust the import path as necessary

const LogoutPage = () => {
	const navigate = useNavigate();
	const { setIsLoggedIn } = useContext(AuthContext);

	useEffect(() => {
		// Send a request to the logout endpoint
		fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
			method: 'GET',
			credentials: 'include', // Include cookies for session management
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				// After successfully logging out
				setIsLoggedIn(false);

				// Redirect to the login page after successful logout
				navigate('/login');
			})
			.catch(error => {
				console.error('There has been a problem with your fetch operation:', error);
				// Optionally handle errors here, e.g., redirect to an error page
			});
	}, [navigate]);

	return (
		<div>
			<h2>Logging out...</h2>
			{/* You can add a loading spinner or other UI elements here */}
		</div>
	);
};

export default LogoutPage;
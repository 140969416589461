import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';

const ENDPOINT = "http://localhost:3001";

function LightSwitch() {
	const [lightColor, setLightColor] = useState('red');

	// useEffect(() => {
	// 	const socket = socketIOClient(ENDPOINT);
	// 	socket.on('lightChange', (data) => {
	// 		setLightColor(data.color);
	// 	});

	// 	return () => socket.disconnect();
	// }, []);

	// const changeLight = () => {
	// 	const socket = socketIOClient(ENDPOINT);
	// 	// socket.emit('lightChange', { color: lightColor === 'red' ? 'green' : 'red' });
	// };

	// return (
	// 	<div className={"change-light"}>
	// 		<div onClick={changeLight} style={{ backgroundColor: lightColor, width: '30px', height: '30px' }}></div>
	// 	</div>
	// );
}

export default LightSwitch;

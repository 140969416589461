// ChessPiece.js
import React, { useState, useContext } from 'react';
import * as THREE from 'three';
import { ThemeContext } from './ThemeContext.js';

const ChessPiece = ({ scale, position, color, geometry, rotation, metalness = 4, roughness = .4 }) => {
	const { theme } = useContext(ThemeContext);
	const [isSelected, setIsSelected] = useState(false);

	const handleClick = () => {
		setIsSelected(!isSelected);
	};

	const material = new THREE.MeshStandardMaterial({
		color: isSelected ? '#ffff00' : color,
		emissive: isSelected ? '#ffff00' : '#000000',
		emissiveIntensity: isSelected ? 0.8 : 0,
		metalness: metalness, // Use the new theme context structure
		roughness: roughness // Use the new theme context structure
	});

	return (
		<group position={position} onClick={handleClick}>
			<mesh
				geometry={geometry}
				scale={scale}
				rotation={rotation}
				receiveShadow castShadow
				material={material}
			/>
		</group>
	);
};

export default ChessPiece;

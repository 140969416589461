// GUICreator.js
export const addGUIFolders = (guiInstance, folders) => {
	if (!guiInstance) {
		// console.error('GUI instance is not initialized.');
		return;
	}

	const addFolderAndControllers = (folderData, parentFolder = null, title) => {
		const { controllers } = folderData;

		let folder;
		if (parentFolder) {
			folder = parentFolder.addFolder(title);
		} else {
			folder = guiInstance.addFolder(title);
		}

		if (controllers) {
			controllers.forEach(controller => {
				if (controller.object.hasOwnProperty(controller.property)) {
					let guiController;
					if (controller.type === 'color') {
						guiController = folder.addColor(controller.object, controller.property).name(controller.label);
					} else {
						guiController = folder.add(controller.object, controller.property, controller.min, controller.max, controller.step).name(controller.label);
					}
					if (controller.onChange) {
						guiController.onChange(controller.onChange);
					}
				} else {
					// console.warn(`Object does not have property "${controller.property}" for controller in folder "${title}".`);
				}
			});
		}

		Object.entries(folderData).forEach(([key, value]) => {
			if (key !== 'controllers') {
				addFolderAndControllers(value, folder, key);
			}
		});
	};

	Object.entries(folders).forEach(([key, value]) => {
		addFolderAndControllers(value, null, key);
	});
};
// ThemeContext.js
import React, { createContext, useState } from 'react';

const defaultTheme = {
	lights: {
		ambientLight: { intensity: 0.1 },
		directionalLight1: { color: 0xffffff, intensity: 1, position: [7, 5, -10] },
		directionalLight2: { color: 0xffffff, intensity: 1, position: [-7, 5, 10] },
		pointLight1: { color: 0xffffff, intensity: 1, distance: 0, position: [10, 1, 0] },
		pointLight2: { color: 0xffffff, intensity: 1, distance: 0, position: [-10, 1, 0] },
	},
	scene: {
		backgroundColor: "#ffffff" // initial load of page this works
	},
	squares: {
		blackSquareColor: '#000000',
		blackSquareMetalness: 0,
		blackSquareRoughness: 0,
		whiteSquareColor: '#ffffff',
		whiteSquareMetalness: 0,
		whiteSquareRoughness: 0
	},
	pieces: {
		player1PiecesColor: '#000000',
		player1PiecesMetalness: 0,
		player1PiecesRoughness: 0,
		player2PiecesColor: '#ffffff',
		player2PiecesMetalness: 0,
		player2PiecesRoughness: 0
	},
	board: {
		color: "#000000",
		metalness: 0,
		roughness: 0
	}
};

export const ThemeContext = createContext({
	theme: defaultTheme,
	setTheme: () => { },
	updateLights: () => { }, // Add this method
});

export const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState(defaultTheme);

	const updateLights = (newLights) => {
		setTheme((prevTheme) => ({
			...prevTheme,
			lights: newLights,
		}));
	};



	return (
		<ThemeContext.Provider value={{ theme, setTheme, updateLights }}>
			{children}
		</ThemeContext.Provider>
	);
};

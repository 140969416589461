// guiFolders.js
import React, { useContext } from 'react';
import { ThemeContext } from "../chessGame/ThemeContext";

export const useChessGameGUIFolders = () => {
	// // console.log('Creating GUI folders...');

	const { theme, setTheme } = useContext(ThemeContext);

	const chessGameGUIFolders = {
		"Show Details": {
			"Game": {
				"Background": {
					controllers: [
						{
							label: 'Color',
							type: 'color',
							property: 'backgroundColor',
							object: theme.scene,
							onChange: (color) => {
								if (!theme.scene) {
									// console.log('Property not found: backgroundColor');
									// console.log('Current theme structure:', theme);
								}
								setTheme((prevTheme) => ({
									...prevTheme,
									scene: {
										...prevTheme.scene,
										backgroundColor: color,
									},
								}));
							},
						},
					]
				},
				"Board": {
					controllers: [
						{
							label: 'Color',
							type: 'color',
							property: 'color',
							object: theme.board,
							onChange: (color) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									board: {
										...prevTheme.board,
										color: color,
									},
								}));
							},
						},
						{
							label: 'Metalness',
							type: 'number',
							property: 'metalness',
							object: theme.board,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									board: {
										...prevTheme.board,
										metalness: value,
									},
								}));
							},
						},
						{
							label: 'Roughness',
							type: 'number',
							property: 'roughness',
							object: theme.board,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									board: {
										...prevTheme.board,
										roughness: value,
									},
								}));
							},
						},


					],
					"Light Square": {
						controllers: [
							{
								label: 'Color',
								type: 'color',
								property: 'whiteSquareColor',
								object: theme.squares,
								onChange: (color) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										squares: {
											...prevTheme.squares,
											whiteSquareColor: color,
										},
									}));
								},
							},
							{
								label: 'Metalness',
								type: 'number',
								property: 'whiteSquareMetalness',
								object: theme.squares,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										squares: {
											...prevTheme.squares,
											whiteSquareMetalness: value,
										},
									}));
								},
							},
							{
								label: 'Roughness',
								type: 'number',
								property: 'whiteSquareRoughness',
								object: theme.squares,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										squares: {
											...prevTheme.squares,
											whiteSquareRoughness: value,
										},
									}));
								},
							},
						]
					},
					"Dark Squares": {
						controllers: [
							{
								label: 'Color',
								type: 'color',
								property: 'blackSquareColor',
								object: theme.squares,
								onChange: (color) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										squares: {
											...prevTheme.squares,
											blackSquareColor: color,
										},
									}));
								},
							},
							{
								label: 'Metalness',
								type: 'number',
								property: 'blackSquareMetalness',
								object: theme.squares,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										squares: {
											...prevTheme.squares,
											blackSquareMetalness: value,
										},
									}));
								},
							},
							{
								label: 'Roughness',
								type: 'number',
								property: 'blackSquareRoughness',
								object: theme.squares,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										squares: {
											...prevTheme.squares,
											blackSquareRoughness: value,
										},
									}));
								},
							},
						]
					},
				},
				"Pieces": {
					"Player 1": {
						controllers: [
							{
								label: 'Player 1 Pieces Color',
								type: 'color',
								property: 'player1PiecesColor',
								object: theme.pieces,
								onChange: (color) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										pieces: {
											...prevTheme.pieces,
											player1PiecesColor: color,
										},
									}));
								},
							},
							{
								label: 'Player 1 Pieces Metalness',
								type: 'number',
								property: 'player1PiecesMetalness',
								object: theme.pieces,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										pieces: {
											...prevTheme.pieces,
											player1PiecesMetalness: value,
										},
									}));
								},
							},
							{
								label: 'Player 1 Pieces Roughness',
								type: 'number',
								property: 'player1PiecesRoughness',
								object: theme.pieces,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										pieces: {
											...prevTheme.pieces,
											player1PiecesRoughness: value,
										},
									}));
								},
							},]
					},
					"Player 2": {
						controllers: [
							{
								label: 'Player 2 Pieces Color',
								type: 'color',
								property: 'player2PiecesColor',
								object: theme.pieces,
								onChange: (color) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										pieces: {
											...prevTheme.pieces,
											player2PiecesColor: color,
										},
									}));
								},
							},
							{
								label: 'Player 2 Pieces Metalness',
								type: 'number',
								property: 'player2PiecesMetalness',
								object: theme.pieces,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										pieces: {
											...prevTheme.pieces,
											player2PiecesMetalness: value,
										},
									}));
								},
							},
							{
								label: 'Player 2 Pieces Roughness',
								type: 'number',
								property: 'player2PiecesRoughness',
								object: theme.pieces,
								min: 0,
								max: 1,
								step: 0.01,
								onChange: (value) => {
									setTheme((prevTheme) => ({
										...prevTheme,
										pieces: {
											...prevTheme.pieces,
											player2PiecesRoughness: value,
										},
									}));
								},
							},
						]
					},
				},
			},
			"Lights": {
				"Ambient Light": {
					controllers: [
						{
							label: 'Intensity',
							type: 'number',
							property: 'intensity',
							object: theme.lights.ambientLight,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										ambientLight: {
											...prevTheme.lights.ambientLight,
											intensity: value,
										},
									},
								}));
							},
						},
					]
				},
				"Directional Light 1": {
					controllers: [

						{
							label: 'Color',
							type: 'color',
							property: 'color',
							object: theme.lights.directionalLight1,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										directionalLight1: {
											...prevTheme.lights.directionalLight1,
											color: value,
										},
									},
								}));
							},
						},
						{
							label: 'Intensity',
							type: 'number',
							property: 'intensity',
							object: theme.lights.directionalLight1,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										directionalLight1: {
											...prevTheme.lights.directionalLight1,
											intensity: value,
										},
									},
								}));
							},
						},
					],
				},
				"Directional Light 2": {
					controllers: [
						{
							label: 'Color',
							type: 'color',
							property: 'color',
							object: theme.lights.directionalLight2,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										directionalLight2: {
											...prevTheme.lights.directionalLight2,
											color: value,
										},
									},
								}));
							},
						},
						{
							label: 'Intensity',
							type: 'number',
							property: 'intensity',
							object: theme.lights.directionalLight2,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										directionalLight2: {
											...prevTheme.lights.directionalLight2,
											intensity: value,
										},
									},
								}));
							},
						},
					],
				},
				"Point Light 1": {
					controllers: [
						{
							label: 'Color',
							type: 'color',
							property: 'color',
							object: theme.lights.pointLight1,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										pointLight1: {
											...prevTheme.lights.pointLight1,
											color: value,
										},
									},
								}));
							},
						},
						{
							label: 'Intensity',
							type: 'number',
							property: 'intensity',
							object: theme.lights.pointLight1,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										pointLight1: {
											...prevTheme.lights.pointLight1,
											intensity: value,
										},
									},
								}));
							},
						},
					],
				},
				"Point Light 2": {
					controllers: [
						{
							label: 'Color',
							type: 'color',
							property: 'color',
							object: theme.lights.pointLight2,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										pointLight2: {
											...prevTheme.lights.pointLight2,
											color: value,
										},
									},
								}));
							},
						},
						{
							label: 'Intensity',
							type: 'number',
							property: 'intensity',
							object: theme.lights.pointLight2,
							min: 0,
							max: 1,
							step: 0.01,
							onChange: (value) => {
								setTheme((prevTheme) => ({
									...prevTheme,
									lights: {
										...prevTheme.lights,
										pointLight2: {
											...prevTheme.lights.pointLight2,
											intensity: value,
										},
									},
								}));
							},
						},
					],
				},

			},

		},
	}


	// // console.log('Test: GUI folders are created successfully', 'Pass');

	return chessGameGUIFolders;



}
// ChessGamePage.js
import React, { useContext, useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { ThemeContext } from '../chessGame/ThemeContext';
import Scene from '../chessGame/Scene';
// import { addGUIFolders } from '../GUIManager';

const ChessGamePage = () => {
	const { theme, setTheme } = useContext(ThemeContext);

	const backgroundFolders = [
		{
			parent: "Show Details",
			title: "Background",
			controllers: [
				{
					label: 'Color',
					type: 'color',
					property: 'backgroundColor',
					object: theme.scene,
					onChange: (color) => {
						setTheme((prevTheme) => ({
							...prevTheme,
							scene: {
								...prevTheme.scene,
								backgroundColor: color,
							},
						}));
					},
				},

			]
		},
	];

	// useEffect(() => {
	// 	addGUIFolders(folders);
	// }, []);

	return (
		<>
			<Canvas
				style={{ backgroundColor: theme.scene.backgroundColor }}
				camera={{ fov: 40, position: [-12, 11, 20] }}
				shadows={true}
			>
				<Scene />
				<OrbitControls
					enableZoom={true}
					enableDamping={false}
				/>
			</Canvas>
		</>
	);
};

export default ChessGamePage;

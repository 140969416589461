import React, { useState } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';

const RegistrationPage = () => {
	const [username, setUsername] = useState('');
	const [displayname, setDisplayName] = useState('');
	const [password, setPassword] = useState('');
	console.log("apiurl", process.env.REACT_APP_API_URL);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`, { username, displayname, password });
			console.log(response.data);
			// Redirect to login page
			window.location.href = '/login';
		} catch (error) {
			console.error('Registration failed:', error);
		}
	};

	return (
		<div>
			<h2>Registration</h2>

			<form onSubmit={handleSubmit} id="registration-form">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<label>
							Display Name:<br />
							<input type="text" value={displayname} onChange={(e) => setDisplayName(e.target.value)} required
								style={{ borderRadius: '2px', padding: '5px' }}
							/>
						</label>
					</Grid>
					<Grid item xs={12}>
						<label>
							Username:<br />
							<input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required
								style={{ borderRadius: '2px', padding: '5px' }}
							/>
						</label>
					</Grid>

					<Grid item xs={12}>
						<label>
							Password:<br />
							<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required
								style={{ borderRadius: '2px', padding: '5px' }}
							/>
						</label>
					</Grid>
					<Grid item xs={12}>
						<button type="submit">Register</button>
					</Grid>
				</Grid>

			</form>
		</div>
	);
};

export default RegistrationPage;

// HomePage.js
import React from 'react';

const HomePage = () => {

  return (
    <>
      <div>Homepage</div>
    </>
  );
};

export default HomePage;

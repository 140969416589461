import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid } from '@mui/material';
import { AuthContext } from '../../AuthContext.js'; // Adjust the import path as necessary
import { useContext } from 'react';

const LoginPage = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate()
	const { setIsLoggedIn } = useContext(AuthContext);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`,
				{ username, password },
				{ withCredentials: true }
			);
			// console.log(response.data);
			setIsLoggedIn(true);

			navigate('/'); // Navigate to the home page on successful login

		} catch (error) {
			console.error('Login failed:', error);
		}
	};

	return (
		<div>
			<h2>Login</h2>
			<form onSubmit={handleSubmit} id="login-form">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<label>
							Username:
							<input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
						</label>
					</Grid>
					<Grid item xs={12}>

						<label>
							Password:
							<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
						</label>
					</Grid>
					<Grid item xs={12}>

						<button type="submit">Login</button>
					</Grid>
				</Grid>

			</form>
		</div>
	);
};

export default LoginPage;

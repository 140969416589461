import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/auth/check-user`, {
			credentials: 'include'
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then(data => {
				setIsLoggedIn(data.isLoggedIn);
				setIsLoading(false);
			})
			.catch(error => {
				console.error('There has been a problem with your fetch operation:', error);
				setIsLoading(false);
			});
	}, []);

	return (
		<AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, isLoading }}>
			{children}
		</AuthContext.Provider>
	);
};
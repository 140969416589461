import React, { useContext, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { ThemeContext } from '../chessGame/ThemeContext'; // Adjust the import path as necessary
// import { addGUIFolders } from '../GUIManager'; // Import addGUIFolders
import Stage from './Stage';

const Scene = () => {
	const { theme, setTheme } = useContext(ThemeContext);
	const { scene } = useThree();

	useEffect(() => {
		// Create lights
		const ambientLight = new THREE.AmbientLight(theme.lights.ambientLight.intensity);
		scene.add(ambientLight);

		const directionalLight1 = new THREE.DirectionalLight(
			theme.lights.directionalLight1.color,
			theme.lights.directionalLight1.intensity
		);
		directionalLight1.position.set(...theme.lights.directionalLight1.position);
		scene.add(directionalLight1);

		const directionalLight2 = new THREE.DirectionalLight(
			theme.lights.directionalLight2.color,
			theme.lights.directionalLight2.intensity
		);
		directionalLight2.position.set(...theme.lights.directionalLight2.position);
		scene.add(directionalLight2);

		const pointLight1 = new THREE.PointLight(
			theme.lights.pointLight1.color,
			theme.lights.pointLight1.intensity,
			theme.lights.pointLight1.distance
		);
		pointLight1.position.set(...theme.lights.pointLight1.position);
		scene.add(pointLight1);

		const pointLight2 = new THREE.PointLight(
			theme.lights.pointLight2.color,
			theme.lights.pointLight2.intensity,
			theme.lights.pointLight2.distance
		);
		pointLight2.position.set(...theme.lights.pointLight2.position);
		scene.add(pointLight2);

		return () => {
			scene.remove(ambientLight);
			scene.remove(directionalLight1);
			scene.remove(directionalLight2);
			scene.remove(pointLight1);
			scene.remove(pointLight2);
		};
	}, [theme, setTheme, scene]);

	return (
		<>
			<Stage />
		</>
	);
};

export default Scene;

// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChessGamePage from './components/pages/ChessGamePage';
import HomePage from './components/pages/HomePage';
import Navigation from './components/navigation';
import LoginPage from './components/pages/LoginPage';
import RegistrationPage from './components/pages/RegistrationPage';
import LogoutPage from './components/pages/LogoutPage';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import GUIManager from './components/GUIManager';

function App() {
  return (
    <>
      <Router>
        <Navigation />
        <GUIManager />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/Register" element={<RegistrationPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/chessgame" element={<ChessGamePage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LightSwitch from './LightSwitch';
import { AuthContext } from '../AuthContext.js';

const Navigation = () => {
	const { isLoggedIn } = useContext(AuthContext);




	return (
		<div className={"navigation-menu"}>
			<LightSwitch />
			<nav>
				<ul>
					<li>
						<Link to="/">Home</Link>
					</li>


						<li>
							<Link to="/login">Login</Link>
						</li>


				
						<>	<li>
							<Link to="/chessgame">Chess Game</Link>
						</li>
							<li>
								<Link to="/register">Register</Link>
							</li>
							<li>
								<Link to="/logout">Logout</Link>
							</li>
						</>
				</ul>
			</nav>
		</div>
	);
};

export default Navigation;
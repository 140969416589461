// Inside Square.js
import { Box } from '@react-three/drei';

const Square = ({ position, color, metalness = 0.5, roughness = 0.5, receiveShadow }) => {
	return (
		<Box
			key={`square-${position[0]}-${position[2]}`}
			position={position}
			args={[1, 0.1, 1]} // Adjust size as needed
			receiveShadow={receiveShadow}
		>
			<meshStandardMaterial attach="material" color={color} metalness={metalness} roughness={roughness} />
		</Box>
	);
};

export default Square;